/* homepage css  */
.btn-primary {
  color: white;
  background: #007e7b;
}
.contact-btn:hover {
  box-shadow: 3px 4px 3px #9e9c9c;
  transition: 250ms;
}
.landing-contact:hover {
  box-shadow: 3px 6px 3px #169290;
}
.btn-primary:hover {
  background: rgb(8, 124, 122);
  box-shadow: 3px 6px 3px #888888;
  transition: 150ms;
}
.landing-contact:hover {
  box-shadow: 2px 2px 3px #169290;
}
.home-link {
  color: #e6dcdc;
}
.home-link:hover {
  color: #fff;
}
.primary-black {
  color: #333333;
}

.fabric-img {
  height: 500px;
}

.border-color {
  border-color: #007e7b;
}

.images-gallery img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.primary-white {
  color: #fafafa;
}

.back-teal {
  background-color: #007e7b;
}
.secondary-white {
  color: #bcbcbc;
}

.text-size-36 {
  font-size: 36px;
}

.hovering li:hover {
  background: #006d6b;
}

/* landing page  */

.btn-primary {
  background-color: #007e7b;
}

footer {
  background: #c4c4c4;
  color: #007e7b;
}

.img-bg-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.7);
}

.img-mob-menu {
  background: rgba(51, 51, 51, 0.7);
}

.menu-li li:hover {
  color: #ffd600;
  text-decoration: underline;
}
footer ul li:hover {
  color: #ffffff;
  font-weight: 500;
}
.font-teal {
  color: #007e7b;
}

.content-right {
  height: auto;
}

.side-sticky-bar {
  position: fixed;
  width: 35px;
  height: 750px;
  left: 0px;
  background: #007e7b;
}
.side-text {
  position: absolute;
  top: 35%;
  left: -88px;
  width: 210px;
  transform: rotate(-90deg) translateX(-45%);
}
.ellipse {
  position: absolute;
  width: 73px;
  height: 73px;
  top: 45%;
  border-radius: 50%;
  left: -8px;

  /* teal */

  background: #007e7b;
}

.side-arrow {
  position: absolute;
  left: 50.94%;
  right: 35.94%;
  top: 35.88%;
  bottom: 21.88%;

  /* Primary white */
}

.input-text {
  background: #f8f5f5;
  border: 0.8px solid #afafaf;
}

.secondary-black {
  color: #565656;
}
.bg-primary-white {
  background: #fafafa;
}

.bg-teal {
  background: #007e7b;
}

.img-bg {
  width: 100%;
  height: 100%;
}

.home-imag {
  position: absolute;
  height: 0;
  width: 100%;
  top: 0px;
  display: none;
  transition: height 2s;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 30.21%,
    #003433 100%
  );
}
.img-bg:hover > div.home-imag {
  display: block;
  height: 100%;
  transition: 250ms;
}

.home-image-section {
  min-height: auto;
}
.staff-photo {
  height: auto;
  width: 100%;
  object-fit: contain;
}
.image-staff {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.nav-menu,
.mob-nav-menu {
  right: -130%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #007e7b;
  transition: 350ms;
  z-index: 60;
  transition-timing-function: ease-in-out;
}
.nav-menu.active,
.mob-nav-menu.active {
  right: 0;
  transition: 750ms;
}
.mob-nav-menu.active {
  transition: 400ms;
}
.mob-nav-menu {
  position: fixed;
  height: auto;
  transition: 300ms;

  overflow-y: scroll;
}

.branch-location {
  position: absolute;
  width: 301px;
  height: 39px;
  right: 10px;
  padding: 2px 2px;
  background: rgba(0, 126, 123, 0.7);
  opacity: 70%;
}
.branch-location h1 {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}
@media (min-width: 768px) {
  .home-image-section {
    min-height: 460px;
  }
  .branch-location {
    position: absolute;
    width: 200px;
    height: 30px;
    right: 0px;
    padding: 0;
    background: rgba(0, 126, 123, 0.7);
    opacity: 70%;
  }
  .branch-location h1 {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
  }
}
@media (min-width: 1024px) {
  .home-image-section {
    min-height: 810px;
  }
}
